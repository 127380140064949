<template>
  <div class="application-market">
    <div class="navigation-bar">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item >应用市场</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wx-app-list">
      <p class="title">微信应用</p>
      <el-row v-for="(row, index) in applicationRows" :key="index" :gutter="10">
        <el-col v-for="item in row" :key="item.id" :span="5">
          <application-item :app-data="item"  @click.native="showApplicationDetail(item)" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApplicationMarket",
  data() {
    return {
      applicationList: [],
    };
  },
  computed: {
    applicationRows: {
      get() {
        let rows = [];
        let row = [];
        for (let i = 0; i < this.applicationList.length; i++) {
          row.push(this.applicationList[i]);
          if (row.length == 4) {
            rows.push(row);
            row = [];
          }
        }
        if (row.length > 0) {
          rows.push(row);
        }
        return rows;
      },
    },
  },
  methods: {
    showApplicationDetail(appData) {
      this.$router.push(`/application/detail/${appData.id}`);
    },
    async loadApplications() {
      try {
        let data = await this.$http.doApi("fw-app-market-wx-app-list");
        this.applicationList = data.rows;
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.loadApplications();
  },
  components: {
    "application-item": () => import("./ApplicationItem"),
  },
};
</script>

<style lang="less">
.application-market {

  .navigation-bar {
    padding: 1rem;
    border-bottom: @hair-border-ccc;
  }

  .wx-app-list {
    width: 98%;
    margin: 0px auto;

    .title {
      border-bottom: 1px solid #cccccc;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
}
</style>